import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import AuthContext from '../context/AuthContext';
import { bookApi } from '../misc/BookApi';
import { handleLogError } from '../misc/Helpers';

class AdminPageDisable extends Component {
  static contextType = AuthContext;

  state = {
    isAdmin: true,
    changeResult: [],
    numberOfMachinesPrio: 3,
    timeWait: 120000,
    remaining: 6,
  };

  componentDidMount() {
    const Auth = this.context;
    const user = Auth.getUser();
    const isAdmin = user.role === 'ADMIN';
    this.setState({ isAdmin });
    this.handleGetChange(user);
  }

  handleGetChange = (user) => {
    bookApi
      .getChange(user)
      .then((response) => {
        this.setState({ changeResult: response.data });
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  handleStartCheckingMachines = () => {
    const Auth = this.context;
    const user = Auth.getUser();
    bookApi
      .startCheckingMachines(user)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  handleSetNumberOfMachinesPrio = (event) => {
    const Auth = this.context;
    const user = Auth.getUser();
    const numberOfMachinesPrio = event.target.value;
    this.setState({ numberOfMachinesPrio });
    bookApi
      .setNumberOfMachinesPrio(user, numberOfMachinesPrio)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  handleSetTimeWait = (event) => {
    const Auth = this.context;
    const user = Auth.getUser();
    const timeWait = event.target.value;
    this.setState({ timeWait });
    bookApi
      .setTimeWait(user, timeWait)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  handleSetRemaining = (event) => {
    const Auth = this.context;
    const user = Auth.getUser();
    const remaining = event.target.value;
    this.setState({ remaining });
    bookApi
      .setRemaining(user, remaining)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  handleStopCheckingMachines = () => {
    const Auth = this.context;
    const user = Auth.getUser();
    bookApi
      .stopCheckingMachines(user)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        handleLogError(error);
      });
  };

  render() {
    if (!this.state.isAdmin) {
      return <Redirect to="/" />;
    } else {
      const { numberOfMachinesPrio, timeWait, remaining } = this.state;

      return (
        <Container style={{ marginTop: '2em' }}>
          <Header as="h1">Change Prio Settings</Header>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as="h2">Start Checking Machines</Header>
                  <p>Click the button to start checking machines:</p>
                  <button onClick={this.handleStartCheckingMachines}>Start</button>
                </Segment>
              </Grid.Column>
              <Grid.Column>

            <Segment>
              <Header as="h2">Stop Checking Machines</Header>
              <p>Click the button to stop checking machines:</p>
              <button onClick={this.handleStopCheckingMachines}>Stop</button>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h2">Number of Machines Prio</Header>
              <p>Set the number of machines to prioritize:</p>
              <input
                type="number"
                value={numberOfMachinesPrio}
                onChange={this.handleSetNumberOfMachinesPrio}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header as="h2">Time Wait</Header>
              <p>Set the time to wait between checks:</p>
              <input type="number" value={timeWait} onChange={this.handleSetTimeWait} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h2">Remaining</Header>
              <p>Set the number of remaining machines:</p>
              <input type="number" value={remaining} onChange={this.handleSetRemaining} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
}
}

export default AdminPageDisable;
