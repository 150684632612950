import React, { useState } from 'react';
import { Table, Button, Icon, Label, Header } from 'semantic-ui-react';
import { bookApi } from '../misc/BookApi';

function BookTable({ rows, user, onPollSuccess, currentSlide, setCurrentSlide, cardCount }) {
  const [polling, setPolling] = useState(false);

   const handlePollClick = (id, ban, change) => {
     const banString = ban ? `${ban},` : '';
     const changeString = change ? change : '';
     const machinePorts = `${id}=${banString}${changeString}`;
     setPolling((prevState) => ({ ...prevState, [id]: true }));
     bookApi.poll(user, machinePorts,cardCount)
       .then((response) => {
         if (response.data === true) {
           console.log("now");
           onPollSuccess(id);
         }
       })
       .catch((error) => {
         console.log(error);
         alert('Failed to poll machine, try again in 5 min');
       })
       .finally(() => {
         setPolling((prevState) => ({ ...prevState, [id]: false }));
       });
   };

 const renderMachineSlide = (machine) => {
    const { id, ban, change } = machine;
    return (
      <Table.Row key={id}>
        <Table.Cell>
          {change
            ? change.split(',').map((portRange, i) => {
                const [start, end] = portRange.split('-');
                if (end) {
                  return (
                    <div key={i} style={{ marginBottom: '5px' }}>
                      <Label color="purple" size="large">
                        {start} - {end}
                      </Label>
                    </div>
                  );
                } else {
                  return (
                    <div key={i} style={{ marginBottom: '5px' }}>
                      <Label color="blue" size="large">
                        {start}
                      </Label>
                    </div>
                  );
                }
              })
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          <Button
            icon
            labelPosition="left"
            color="teal"
            disabled={polling[id]}
            style={{ marginLeft: 'auto', marginRight: 'auto',marginTop: 'auto', marginBottom: 'auto', display: 'block', fontSize: '1.4em' }}
            onClick={() => handlePollClick(id, ban, change)}
          >
            <Icon name="exchange" />
            Poll
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };


  const onNextSlide = () => setCurrentSlide(Math.min(currentSlide + 1, Math.min(rows.length, 16)));
  const onPrevSlide = () => setCurrentSlide(Math.max(currentSlide - 1, 0));

  return (
     <>
       <div className="book-table-container" style={{ marginTop: '20px' }}>
         {rows.length === 0 && (
           <Header as="h2" textAlign="center" style={{ marginBottom: '20px' }}>
             Nothing to change
           </Header>
         )}
         {rows.length > 0 && currentSlide >= 0 && currentSlide < rows.length && (
           <>
             <Header as="h2" textAlign="center" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
               Machine {rows[currentSlide].id}
             </Header>
             <Table celled unstackable style={{ textAlign: 'center' }}>
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell width={5} style={{ fontSize: '1.2em' }}>Change</Table.HeaderCell>
                   <Table.HeaderCell width={6} style={{ fontSize: '1.2em' }}>Poll</Table.HeaderCell>
                 </Table.Row>
               </Table.Header>
               <Table.Body>{renderMachineSlide(rows[currentSlide])}</Table.Body>
             </Table>
           </>
         )}
       </div>
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
         <Button.Group>
           <Button onClick={onPrevSlide} disabled={currentSlide === 0}>
             Previous
           </Button>
           <Button onClick={onNextSlide} disabled={currentSlide >= rows.length - 1}>
        Next
      </Button>
    </Button.Group>
  </div>
</>
);
}

export default BookTable;
