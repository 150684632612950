import axios from 'axios'
import { config } from '../../Constants'

export const bookApi = {
  authenticate,
  signup,
  poll,
  getChange,
}

function authenticate(username, password) {
  return instance.post('/auth/authenticate', { username, password }, {
    headers: { 'Content-type': 'application/json' }
  })
}

function signup(user) {
  return instance.post('/auth/signup', user, {
    headers: { 'Content-type': 'application/json' }
  })
}


function getChange(user) {
  const url = '/api/change'
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}


function poll(user,machinePorts,numToChange) {
  const url = '/api/change/poll?text='+machinePorts+'&numToChange='+numToChange
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function startCheckingMachines(user) {
  const url = '/api/prio/start'
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function setNumberOfMachinesPrio(user, numberOfMachinesPrio) {
  const url = '/api/prio/num?text=' + numberOfMachinesPrio
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function setTimeWait(user, timeWait) {
  const url = '/api/prio/timeWait?text=' + timeWait
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function setRemaining(user, remaining) {
  const url = '/api/prio/remaining?text=' + remaining
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function stopCheckingMachines(user) {
  const url = '/api/prio/stopCheckingMachines'
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}




// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
  withCredentials: true
})

// -- Helper functions

function basicAuth(user) {
  return `Basic ${user.authdata}`
}
