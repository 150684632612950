const prod = {
  url: {
    //API_BASE_URL: 'https://myapp.herokuapp.com',
    API_BASE_URL: 'http://api.sim.sale'
    //API_BASE_URL: 'http://localhost:8080'
  }
}

const dev = {
  url: {
   //API_BASE_URL: 'http://localhost:8080'
    API_BASE_URL: 'http://api.sim.sale'
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
