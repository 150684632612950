import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Header, Icon, Segment, Dimmer, Loader, Button } from 'semantic-ui-react';
import AuthContext from '../context/AuthContext';
import { bookApi } from '../misc/BookApi';
import { handleLogError } from '../misc/Helpers';
import BookTable from './BookTable';
import jokesData from './jokes.json'; // Adjust the path to where your jokes.json is located

function AdminPage() {
  const authContext = useContext(AuthContext);
   const user = authContext.getUser();
   const isAdmin = user.role === 'ADMIN';
    const [currentSlide, setCurrentSlide] = useState(0);



    const [loading, setLoading] = useState(true);
    const [changeResult, setChangeResult] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const [count, setCount] = useState(0);
    const [joke, setJoke] = useState('');

    useEffect(() => {
      // Function to select a random joke
      const selectRandomJoke = () => {
          const randomIndex = Math.floor(Math.random() * jokesData.length);
          setJoke(jokesData[randomIndex].joke);
      };

      selectRandomJoke();



    if (isAdmin) {
      setLoading(true);
      bookApi
        .getChange(user)
        .then((response) => {
          const responseData = response.data;
                    const extractedCount = responseData.pop(); // Extract the last element and assign it to 'extractedCount'
                    setCount(extractedCount); // Set the 'count' state with the extracted value
                    const filteredData = responseData; // The remaining data
          const processedData = filteredData.map((row) => {
            const [id, rest] = row.split('=');
            const [ban, change] = rest.split('|');
            return { id: parseInt(id), ban: ban === '' ? null : ban, change: change === '' ? null : change };
          });
          console.log(processedData);
          setChangeResult(processedData);

            const initialCardCount = response.data.reduce((total, row) => {
                const [, rest] = row.split('=');
                if (rest) {
                    const [ban, changeStr] = rest.split('|');
                    const banCount = ban ? ban.split(',').length : 0;
                    const changeCount = changeStr
                        ? changeStr
                            .split(',')
                            .reduce((acc, portRange) => {
                                const [start, end] = portRange.split('-');
                                return acc + (end ? parseInt(end) - parseInt(start) + 1 : 1);
                            }, 0)
                        : 0;
                    return total + banCount + changeCount;
                }
                return total;
            }, 0);
            setCardCount(initialCardCount);

        })
        .catch((error) => {
          handleLogError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isAdmin,refreshKey]);




const onPollSuccess = (id) => {
  const removedMachine = changeResult.find((row) => row.id === id);
  const removedBanCount = removedMachine.ban ? removedMachine.ban.split(',').length : 0;
  const removedChangeCount = removedMachine.change
    ? removedMachine.change
        .split(',')
        .reduce((acc, portRange) => {
          const [start, end] = portRange.split('-');
          return end ? acc + parseInt(end) - parseInt(start) + 1 : acc + 1;
        }, 0)
    : 0;

  setCardCount(cardCount - removedBanCount - removedChangeCount);
  setChangeResult((prevState) => prevState.filter((row) => row.id !== id));

  // Add this check
  if (currentSlide >= changeResult.length - 1) {
    setCurrentSlide(Math.max(currentSlide - 1, 0));
  }
};

const handleRefresh = () => {
  setRefreshKey((prevKey) => prevKey + 1);
};




  const getColorForCardCount = (count) => {
    if (count < 50) return 'green';
    if (count < 100) return 'orange';
    return 'red';
  };

  if (!isAdmin) {
    return <Redirect to="/" />;
  } else {
    const cardCountColor = getColorForCardCount(cardCount);

    return (
      <Container>
        <Dimmer active={loading}>
          <Loader>{joke || 'Loading joke...'}</Loader>
        </Dimmer>
        <Grid stackable columns={1}>
          <Grid.Column>
            <BookTable
              rows={changeResult}
              user={user}
              onPollSuccess={onPollSuccess}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              cardCount={cardCount}
            />

                                                       <p style={{ color: cardCountColor, fontWeight: 'bold', marginTop: '1em', fontSize: '1.2em', textAlign: 'center' }}>
                                                       <b>{cardCount}</b> cards to change
                                                       </p>
                                                       <Button
                                                         icon
                                                         labelPosition="left"
                                                         color="blue"
                                                         onClick={handleRefresh}
                                                         style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '1em' }}
                                                       >
                                                         <Icon name="refresh" />
                                                         Refresh
                                                       </Button>
 <p
           style={{
             marginTop: '1em',
             fontSize: '1.2em',
             textAlign: 'center',
             color: 'teal',
             fontStyle: 'italic',
           }}
         >
           Earnings: {count * 20} €
         </p>
                                                       </Grid.Column>
                                                       </Grid>
                                                       </Container>
                                                       );
                                                       }
                                                       }

                                                       export default AdminPage;
