import React, { Component } from 'react'
import { Container, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { handleLogError } from '../misc/Helpers'

class Home extends Component {
  state = {
    isLoading: false
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    try {

    } catch (error) {
      handleLogError(error)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <Segment basic style={{ marginTop: window.innerHeight / 2 }}>
          <Dimmer active inverted>
            <Loader inverted size='huge'>Loading</Loader>
          </Dimmer>
        </Segment>
      )
    } else {
   //   const {} = this.state
      return (
        <Container text>
          Nothing here...
        </Container>
      )
    }
  }
}

export default Home
